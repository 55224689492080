<template lang="pug">
  span
    confirmation-dialog(
      v-model="showModal"
      @okBtnClicked="removeCourse"
      @failBtnClicked="showModal = !showModal"
      title="Delete course"
      descr="Are you sure you want to delete course?"
      okText="ok"
      failText="cancel"
    )
    v-btn(
      icon
      :disabled="loading"
      @click="showModal = true"
    )
      v-icon mdi-delete
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'


export default {
  props: {
    ID: {
      type: Number,
      required: true
    },
  },
  inject: ['svc'],
  mixins: [ errorsMixin ],
  data() {
    return {
      loading: false,
      showModal: false
    }
  },
  methods: {
    async removeCourse() {
      this.loading = true;
      try {
        await this.svc.removeCourse(this.ID);
        this.loading = false;
        this.showModal = false;
        this.$emit('onSuccess');
      } catch (err) {
        this.processError(err);
        this.showModal = false;
        this.loading = false;
      }
    }
  },
  components: {
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue'),
  }
}
</script>
